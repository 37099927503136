import Breakpoint from '../utilities/breakpoint'
import widthResize from '../utilities/widthResize'
const Velocity = require('velocity-animate')
require('velocity-animate/velocity.ui')

class LoginDropdown {
  constructor () {
    this.navItems = document.querySelectorAll('.nav-login-item--dropdown')
    this.breakpoint = 0
    this.enterItemPointer = e => this.enterItem(e.target)
    this.exitItemPointer = e => this.exitItem(e.target)
  }

  init () {
    this.setBreakpoints()
  }

  addEvents () {
    this.navItems.forEach(item => {
      item.addEventListener('mouseenter', this.enterItemPointer)
      item.addEventListener('mouseleave', this.exitItemPointer)
      item.addEventListener('click', this.enterItemPointer)
    })
  }

  enterItem (item) {
    const dropdown = item.querySelector('.nav-dropdown')
    this.show(dropdown)
  }

  exitItem (item) {
    const dropdown = item.querySelector('.nav-dropdown')
    this.hide(dropdown)
  }

  removeEvents () {
    this.navItems.forEach(item => {
      item.removeEventListener('mouseenter', this.enterItemPointer)
      item.removeEventListener('mouseleave', this.exitItemPointer)
      item.removeEventListener('click', this.exitItemPointer)
    })
  }

  setForDesktop () {
    this.addEvents()
  }

  setForMobile () {
    this.removeEvents()
    this.resetAll()
  }

  resetAll () {
    this.navItems.forEach(item => {
      const dropdown = item.querySelector('.nav-dropdown')
      Velocity(dropdown, 'finish')
      dropdown.removeAttribute('style')
    })
  }

  show (dropdown) {
    if (dropdown.classList.contains('velocity-animating')) {
      Velocity(dropdown, 'stop')
    }
    Velocity(
      dropdown,
      { opacity: 1, top: '100%', zIndex: 1 },
      { display: 'block' },
      'easeOutSine'
    )
  }

  hide (dropdown) {
    if (dropdown.classList.contains('velocity-animating')) {
      Velocity(dropdown, 'stop')
    }

    dropdown.style.zIndex = -1
    Velocity(
      dropdown,
      { opacity: 0, top: '80%' },
      { display: 'none' },
      'easeInSine'
    )
  }

  setBreakpoints () {
    let bp = new Breakpoint()

    bp.checkBreakpoint(
      this.breakpoint,
      () => this.setForMobile(),
      () => this.setForDesktop(),
      true
    )

    widthResize(
      bp.checkBreakpoint.bind(
        this,
        this.breakpoint,
        () => this.setForMobile(),
        () => this.setForDesktop()
      )
    )
  }
}

export default LoginDropdown
