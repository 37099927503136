import $ from 'jquery'

const tabToggle = () => {
  $('.tab-title').on('click', function () {
    let id = $(this).attr('data-id')
    let blockId = $(this).closest('.element').attr('id')
    if (!$(`.tab__${id}`).hasClass('tab__active')) {
      hideTabs(blockId)
      $(`#${blockId}`).find(`.tab__${id}`).addClass('tab__active')
      $(this).addClass('tab-title__active')
    }
  })
}

const hideTabs = (blockId) => {
  $(`#${blockId}`).find('.tab-title').removeClass('tab-title__active')
  $(`#${blockId}`).find('.tab').removeClass('tab__active')
}

export default tabToggle
