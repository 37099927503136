import $ from 'jquery'
import Parsley from 'parsleyjs' // eslint-disable-line no-unused-vars

if ($('#AccountForm_AccountForm').length) {
  $('#AccountForm_AccountForm')
    .parsley()
    .on('field:validate', function () {
      const element = this.$element[0]
      const pw2 = $('#AccountForm_AccountForm_NewPassword2')
      const pw1 = $('#AccountForm_AccountForm_NewPassword1')

      if (element.id === 'AccountForm_AccountForm_OldPassword') {
        if (element.value === '') {
          pw1.attr('data-parsley-required', false)
          pw2.attr('data-parsley-required', false)
        } else {
          pw1.attr('data-parsley-required', true)
          pw2.attr('data-parsley-required', true)
        }

        pw1.parsley().reset()
        pw2.parsley().reset()
      }

      if (element.id === 'AccountForm_AccountForm_NewPassword1') {
        pw2.parsley().validate()
      }
    })
}
