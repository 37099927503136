import $ from 'jquery'
import slick from 'slick-carousel' // eslint-disable-line

const intakesTicker = () => {
  let tickerWidth = calculateTickerWidth()

  createSlider(tickerWidth)
  $(window).resize(function () {
    createSlider(tickerWidth)
  })
}

const createSlider = function (tickerWidth) {
  let linkWidth = 0

  if ($('.intakes-ticker-link').outerWidth()) {
    linkWidth = $('.intakes-ticker-link').outerWidth()
  }

  if (tickerWidth > ($(window).outerWidth() - linkWidth)) {
    if (!$('.slick-initialized').length) {
      $('.ticker-slider').slick({
        slidesToScroll: 4,
        infinite: true,
        speed: 300,
        variableWidth: true,
        nextArrow: "<button class='ticker-next'><span class='sr-only'>Next intakes</span><svg class='svg'><use xlink:href='images/icons.svg#chevron-right-dark'/></svg></button>",
        prevArrow: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToScroll: 1
            }
          }
        ]
      })
    }
    $('.ticker-overlay').css('display', 'block')
    $('.intakes-ticker-link').css('display', 'none')
  } else {
    if ($('.slick-initialized').length) {
      $('.ticker-slider').slick('unslick')
    }
    $('.intakes-ticker-link').css('display', 'block')
    $('.ticker-overlay').css('display', 'none')
  }
}

const calculateTickerWidth = () => {
  let tickerWidth = 0
  $('.intakes-ticker-block').each(function () {
    tickerWidth += $(this).outerWidth()
  })

  return tickerWidth
}

export default intakesTicker
