class ProfileToggle {
  constructor () {
    this.openCreateAccountButton = document.querySelector('.open-create-account-button')
    this.backButton = document.querySelector('.go-back-button')
    this.profileIntro = document.querySelector('.profile-intro')
    this.registrationForm = document.querySelector('.registration-form')
    this.accountsForm = document.querySelector('.account-block-form')
    if (this.accountsForm) {
      this.accountsForm = document.querySelector('.account-block-form')
    }
    this.errorMessage = document.querySelector('.message.error')
  }

  init () {
    if (this.accountsForm) {
      this.createEvents()
    }
  }

  createEvents () {
    if (this.openCreateAccountButton) {
      this.openCreateAccountButton.addEventListener('click', () => this.openForm())
    }

    if (this.errorMessage) {
      this.openForm()
    }

    if (this.backButton) {
      this.backButton.addEventListener('click', () => this.closeForm())
    }
  }

  openForm () {
    this.registrationForm.classList.remove('hide')
    this.profileIntro.classList.add('hide')
  }

  closeForm () {
    this.registrationForm.classList.add('hide')
    this.profileIntro.classList.remove('hide')
  }
}

export default ProfileToggle
