// Send contact form enquiry type to GTM dataLayer

class ContactFormSubmission {
  constructor () {
    this.submission = document.querySelector('#new-submission')
  }

  init () {
    this.sendToGTM()
  }

  sendToGTM () {
    if (this.submission) {
      this.enquiryType = this.submission.dataset.enquiry

      // eslint-disable-next-line
      dataLayer.push({
        event: 'formSubmitted',
        formType: 'Contact us',
        enquiryType: this.enquiryType
      })
    }
  }
}

export default ContactFormSubmission
