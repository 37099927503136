import closest from '../utilities/closest'
class Hamburger {
  constructor () {
    this.hamburger = document.querySelector('[data-hamburger]')
    if (!this.hamburger.classList.contains('hamburger')) {
      this.hamburger = closest(this.hamburger, '.hamburger')
    }
  }

  el () {
    return this.hamburger
  }

  close () {
    this.hamburger.classList.remove('is-active')
    this.hamburger.setAttribute('aria-expanded', false)
  }

  open () {
    this.hamburger.classList.add('is-active')
    this.hamburger.setAttribute('aria-expanded', true)
  }
}

export default Hamburger
