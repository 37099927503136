const Velocity = require('velocity-animate')
require('velocity-animate/velocity.ui')

class Skiplinks {
  constructor () {
    this.dropdown = document.querySelector('.skiplinks-dropdown')
    this.skiplinks = document.querySelectorAll('.skiplinks-desktop span')
  }

  init () {
    this.addEvents()
  }

  addEvents () {
    // event for desktop
    if (this.skiplinks.length) {
      this.skiplinks.forEach(item => {
        item.addEventListener('click', () => this.jumpToSection(item))
      })
    }

    // event for mobile
    if (this.dropdown) {
      this.dropdown.addEventListener('change', () => this.jumpToSection(this.dropdown), false)
    }
  }

  jumpToSection (el) {
    if (el.options) {
      this.option = el.options[el.selectedIndex]
      this.sectionID = this.option.dataset.id
    } else {
      this.sectionID = el.dataset.id
    }

    // get element by id and its offset top
    this.section = document.getElementById(this.sectionID)
    this.offset = this.section.offsetTop
    // animate
    this.animate(this.offset)
  }

  animate (offset) {
    Velocity(document.body, 'scroll', { offset: offset, mobileHA: false })
  }
}

export default Skiplinks
