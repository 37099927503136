import Breakpoint from '../utilities/breakpoint'
import widthResize from '../utilities/widthResize'
const Velocity = require('velocity-animate')
require('velocity-animate/velocity.ui')

class SearchFilter {
  constructor () {
    this.searchFilters = document.querySelectorAll('.hero-filter-title')
    this.openFilterButton = document.querySelector('.open-filter-button')
    this.filterForm = document.querySelector('.hero-filter-form')
    this.searchButton = document.querySelector('.filter-search-button')
    if (this.filterForm) {
      this.filterInputs = this.filterForm.querySelectorAll('input[type=checkbox]')
    }
    this.filterContainer = document.querySelector('.hero-search-inner')
    this.breakpoint = 1024

    this.closeFormPointer = e =>
      this.onFocusOut(
        this.filterContainer,
        this.filterForm,
        this.openFilterButton,
        e
      )
    this.toggleFormPointer = () =>
      this.ToggleForm(this.filterForm, this.openFilterButton)
  }

  init () {
    if (this.filterForm) {
      this.setBreakpoints()
      this.createEvents()
    }
  }

  // Below 1024 px
  setForMobile () {
    for (var i = 0; i < this.filterInputs.length; i++) {
      let input = this.filterInputs[i]
      if (input.checked) {
        this.openFilterButton.innerHTML = 'Change Filters'
      }
    }

    if (this.filterForm.classList.contains('form-open')) {
      this.closeForm(this.filterForm, this.openFilterButton)
    }

    if (this.openFilterButton) {
      this.openFilterButton.addEventListener('click', this.toggleFormPointer)
    }
  }

  // above 1024px
  setForDesktop () {
    if (this.filterForm.classList.contains('form-closed')) {
      this.openForm(this.filterForm, this.openFilterButton)
    }

    document.removeEventListener('click', this.toggleFormPointer)
    document.removeEventListener('click', this.closeFormPointer)
  }

  createEvents () {
    for (var i = 0; i < this.searchFilters.length; i++) {
      let filter = this.searchFilters[i]
      filter.addEventListener(
        'click',
        () => this.ToggleFilter(filter.parentNode),
        false
      )
    }
  }

  // Functions to open and close the form on mobile
  ToggleForm (el, button) {
    if (el.style.display === '' || el.style.display === 'none') {
      return this.openForm(el, button)
    } else {
      return this.closeForm(el, button)
    }
  }

  openForm (el, button) {
    el.classList.remove('form-closed')
    el.classList.add('form-open')

    Velocity(
      button.parentNode,
      'fadeOut',
      { duration: 200 },
      { display: 'none' },
      'easeOutSine'
    )

    Velocity(
      el,
      'slideDown',
      { delay: 200 },
      { duration: 600 },
      { display: 'block' },
      'easeOutSine'
    )

    document.addEventListener('click', this.closeFormPointer)
  }

  closeForm (el, button) {
    el.classList.add('form-closed')
    el.classList.remove('form-open')

    Velocity(
      el,
      'slideUp',
      { duration: 600 },
      { display: 'none' },
      'easeOutSine'
    )

    Velocity(
      button.parentNode,
      'fadeIn',
      { delay: 600 },
      { duration: 200 },
      { display: 'block' },
      'easeOutSine'
    )
  }

  onFocusOut (container, el, button, e = null) {
    if (!container.contains(e.target) && el.classList.contains('form-open')) {
      this.closeForm(el, button)

      document.removeEventListener('click', this.closeFormPointer)
    }
  }

  // Functions to open and close the filters within the form
  ToggleFilter (el) {
    if (!el.classList.contains('filter-open')) {
      return this.openFilter(el)
    } else {
      return this.closeFilter(el)
    }
  }

  closeFilter (el) {
    let click = el.querySelector('.hero-filter-content')
    el.classList.remove('filter-open')

    Velocity(click, { opacity: 0 }, { duration: 200 })

    Velocity(click, 'slideUp', { duration: 200 })
  }

  openFilter (el) {
    let click = el.querySelector('.hero-filter-content')
    el.classList.add('filter-open')

    Velocity(click, 'slideDown', { duration: 200 })

    Velocity(click, { opacity: 1 }, { duration: 200 })
  }

  // Set breakpoints
  setBreakpoints () {
    let bp = new Breakpoint()

    bp.checkBreakpoint(
      this.breakpoint,
      () => this.setForMobile(),
      () => this.setForDesktop(),
      true
    )

    widthResize(
      bp.checkBreakpoint.bind(
        this,
        this.breakpoint,
        () => this.setForMobile(),
        () => this.setForDesktop()
      )
    )
  }
}

export default SearchFilter
