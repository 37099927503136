// Back to top Button
import $ from 'jquery'
const Velocity = require('velocity-animate')
require('velocity-animate/velocity.ui')

class Backtotop {
  init () {
    this.called = true
    const bttc = document.querySelector('.back-to-top')
    if (bttc) {
      $(bttc).css('display', 'none')

      this.pageScroll()

      this.backToTop()
    }
  }

  pageScroll () {
    const atFooter = this.atFooter
    $(window).scroll(function () {
      const bttc = document.querySelector('.back-to-top')
      const bttcInner = document.querySelector('.back-to-top-inner')

      if ($(this).scrollTop() > 100) {
        if (
          !bttc.classList.contains('showing') &&
          !bttc.classList.contains('velocity-animating')
        ) {
          $(bttc).css('display', 'flex')
          Velocity(
            bttc,
            {
              opacity: 1
            },
            {
              duration: 400,
              complete: function () {
                bttc.classList.add('showing')
              }
            }
          )
        }
      } else {
        if (
          bttc.classList.contains('showing') &&
          !bttc.classList.contains('velocity-animating')
        ) {
          Velocity(
            bttc,
            {
              opacity: 0
            },
            {
              duration: 400,
              complete: function () {
                $(bttc).css('display', 'none')
                bttc.classList.remove('showing')
              }
            }
          )
        }
      }

      if (atFooter()) {
        bttcInner.classList.remove('back-to-top-inner--fix')
      } else {
        bttcInner.classList.add('back-to-top-inner--fix')
      }
    })
  }

  backToTop () {
    const bttb = document.querySelector('.back-to-top-inner')
    if (bttb) {
      bttb.addEventListener('click', function () {
        const htmlBody = document.querySelector('html, body')
        Velocity(
          htmlBody,
          'scroll',
          {
            offset: 0,
            mobileHA: false
          },
          {
            duration: 400
          }
        )
        return false
      })
    }
  }

  atFooter (offset = 0) {
    const bttc = document.querySelector('.back-to-top-container')
    const bttcRec = bttc.getBoundingClientRect()

    return bttcRec.bottom + offset <= window.innerHeight
  }
}

export default Backtotop
