import 'nodelist-foreach-polyfill'
import 'svgxuse'
import objectFitImages from 'object-fit-images'
import Fancybox from './components/fancyBox'
import Backtotop from './components/backToTop'
import tabToggle from './components/tabToggle'
import MenuDropdown from './components/menuDropDown'
import LoginDropdown from './components/loginDropdown'
import MobileMenu from './components/mobileMenu'
import Accordion from './components/accordion'
import Search from './components/search'
import Skiplinks from './components/skipLinks'
import SearchFilter from './components/searchFilter'
import setGridFilter from './components/gridFilter'
import intakesTicker from './components/intakesTicker'
import ProfileToggle from './components/profileToggle'
import FavouriteItems from './components/favourite'
import AccountSettings from './components/accountSettings'
import formAccessibility from './components/formAccessibility'
import ContactFormSubmission from './components/contactFormSubmission'
import './components/validation'
import setFileName from './components/fileUpload'
import $ from 'jquery'

window.jQuery = $
// Add polyfill for css object-fit
document.addEventListener('DOMContentLoaded', () => objectFitImages())

class AppObject {
  constructor (basepath) {
    this.path = basepath
  }
  init () {
    let backtotop = new Backtotop()

    let fancybox = new Fancybox()
    fancybox.init()

    const menudropdown = new MenuDropdown()
    menudropdown.init()

    const logindropdown = new LoginDropdown()
    logindropdown.init()

    const mobilemenu = new MobileMenu()
    mobilemenu.init()

    const accordion = new Accordion()
    accordion.init()
    window.accordion = accordion

    const skiplinks = new Skiplinks()
    skiplinks.init()

    const searchfilter = new SearchFilter()
    searchfilter.init()

    const profileToggle = new ProfileToggle()
    profileToggle.init()

    const favouriteItems = new FavouriteItems()
    favouriteItems.init()

    const accountSettings = new AccountSettings()
    accountSettings.init()

    const contactFormSubmission = new ContactFormSubmission()
    contactFormSubmission.init()

    // call back to top function after passion finder results has been injected
    if (document.querySelector('.PassionFinderPage')) {
      setInterval(function () {
        if (document.querySelector('.back-to-top') && !backtotop.called) {
          backtotop.init()
        }
      }, 500)
    } else {
      backtotop.init()
    }

    setGridFilter()

    // jquery functions
    tabToggle()
    intakesTicker()
    formAccessibility()

    const search = new Search(
      '.nav-secondary-list-search',
      '.search-toggle',
      '.search-close'
    )
    search.init()

    const searchTop = new Search(
      '.header-top-search',
      '.search-toggle',
      '.search-close'
    )
    searchTop.init()

    setFileName()
  }
}

const app = new AppObject('/')
app.init()

// check for mobile device and passion finder pagetype, add class to override hover styling on mobile
if (/Mobi|Android/i.test(navigator.userAgent) && $('body').hasClass('PassionFinderPage')) {
  $('body').addClass('passion-mobile')
}
