export default class Breakpoint {
  constructor () {
    this.beforeBreakpoint = true
  }

  checkBreakpoint (breakpoint, beforeCallback, afterCallback, init = false) {
    if (breakpoint < window.innerWidth) {
      if (this.beforeBreakpoint || init) {
        if (afterCallback !== null) {
          afterCallback()
        }
        this.beforeBreakpoint = false
      }
    } else {
      if (!this.beforeBreakpoint || init) {
        if (beforeCallback !== null) {
          beforeCallback()
        }
        this.beforeBreakpoint = true
      }
    }
  }
}
