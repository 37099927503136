const setFileName = () => {
  const fileName = document.querySelector('.avatar-upload-field-name')
  const fileField = document.querySelector('.upload-image input[type="file"]')

  if (fileName && fileField) {
    fileField.addEventListener(
      'change',
      () => {
        fileName.innerHTML = fileField.files[0] ? fileField.files[0].name : ''
      },
      false
    )
  }
}

export default setFileName
