import $ from 'jquery'
const Velocity = require('velocity-animate')
require('velocity-animate/velocity.ui')

class Search {
  constructor (searchContainer, openButton, closeButton) {
    this.searchContainer = document.querySelector(searchContainer)
    this.searchInput = this.searchContainer.querySelector('.search-input')
    this.openButton = this.searchContainer.querySelector(openButton)
    this.closeButton = this.searchContainer.querySelector(closeButton)

    this.breakpoint = 1024
    this.openSearchPointer = e => this.openSearch(e)
    this.closeSearchPointer = e => this.closeSearch(e)
    this.form = $('#SearchForm_SilverStripe_CMS_Search_SearchForm')
    this.formInput = $(this.searchInput).first()
  }

  init () {
    this.addEvents()
  }

  addEvents () {
    this.openButton.addEventListener('click', this.openSearchPointer, true)
    this.closeButton.addEventListener('click', this.closeSearchPointer, true)
  }

  submitSearch () {
    if (this.formInput.val()) {
      this.form.submit()
    }
  }

  openSearch (e) {
    e.preventDefault()
    if (!this.searchContainer.classList.contains('search--open')) {
      this.openItem()
      this.openButton.setAttribute('aria-label', 'submit search')
    }
    this.submitSearch()
  }

  closeSearch (e) {
    e.preventDefault()
    if (this.searchContainer.classList.contains('search--open')) {
      this.closeItem()
      this.openButton.setAttribute('aria-label', 'open search field')
    }
  }

  closeItem () {
    const searchInner = this.searchContainer.querySelector('.search')
    this.searchContainer.classList.remove('search--open')
    Velocity(searchInner, { width: '54px' }, 'easeInSine')
    this.openButton.focus()
  }

  openItem () {
    const searchInner = this.searchContainer.querySelector('.search')
    this.searchContainer.classList.add('search--open')
    Velocity(searchInner, { width: '100%' }, 'easeOutSine')
    this.searchInput.focus()
  }
}

export default Search
