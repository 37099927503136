import axios from 'axios'
import closest from '../utilities/closest'

class FavouriteItems {
  constructor () {
    this.favouriteSelector = '[data-favourite]'
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  }

  init () {
    this.favouriteItems = document.querySelectorAll(this.favouriteSelector)
    if (this.favouriteItems) {
      document.addEventListener('click', e => {
        const item = closest(e.target, this.favouriteSelector)
        if (item) {
          e.preventDefault()
          if (item.dataset.favoured === 'true') {
            this.removeFavourite(item)
          } else {
            this.addFavourite(item)
          }
        }
      })
    }
  }

  addFavourite (item) {
    const id = item.dataset.favourite
    const type = item.dataset.favouriteType
    const date = item.dataset.favouriteDate

    let url = `${
      location.origin
    }/favourite/add/?type=${type}&favourite_id=${id}`

    if (date) {
      url += `&date=${date}`
    }

    item.dataset.favoured = true

    axios
      .get(url)
      .then(response => {
        if (response.data.includes('/account/login')) {
          window.location = response.data
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  removeFavourite (item) {
    const id = item.dataset.favourite
    const type = item.dataset.favouriteType
    const date = item.dataset.favouriteDate

    let url = `${
      location.origin
    }/favourite/remove?type=${type}&favourite_id=${id}`

    if (date) {
      url += `&date=${date}`
    }

    item.dataset.favoured = false
    this.updateFavouriteCount(item)
    this.hideFavouriteItem(item)
    axios
      .get(url)
      .then(response => {})
      .catch(error => {
        console.log(error)
      })
  }

  updateFavouriteCount (item) {
    const favouriteGroup = item.closest('[data-favourite-group]')
    const count = favouriteGroup
      ? favouriteGroup.querySelector('[data-favourite-count]')
      : null

    if (count) {
      const favouredItems = favouriteGroup.querySelectorAll(
        '[data-favoured="true"]'
      )

      count.textContent = favouredItems.length
      count.dataset.favouriteCount = favouredItems.length
    }
  }

  hideFavouriteItem (item) {
    const accountFavouriteContainer = item.closest('.account-favoured-items')
    const favouriteItemContainer = item.closest('[class*="role--"]')

    if (accountFavouriteContainer && favouriteItemContainer) {
      favouriteItemContainer.classList.add('hide')
    }
  }
}

export default FavouriteItems
