const widthResize = callback => {
  let lastWindowWidth = window.innerWidth

  window.addEventListener('resize', () => {
    let windowWidth = window.innerWidth
    if (lastWindowWidth !== windowWidth) {
      callback()
      lastWindowWidth = windowWidth
    }
  })
}

export default widthResize
