import $ from 'jquery'

const formAccessibility = () => {
  // ensure unique IDs for search forms
  $('.hero-filter-form').attr('id', 'SearchForm_SilverStripe_CMS_Search_SearchForm_HeroForm')
  $('.nav-secondary-list-search > form').attr('id', 'SearchForm_SilverStripe_CMS_Search_SearchForm_MobileSearchForm')

  // focus style for log in form
  $('#MemberLoginForm_LoginForm_Remember').focus(() => {
    $('#MemberLoginForm_LoginForm_Remember').next().addClass('checkbox-focus')
  })

  $('#MemberLoginForm_LoginForm_Remember').focusout(() => {
    $('#MemberLoginForm_LoginForm_Remember').next().removeClass('checkbox-focus')
  })
}

export default formAccessibility
