import Velocity from 'velocity-animate'
import 'velocity-animate/velocity.ui'
import Breakpoint from '../utilities/breakpoint'
import widthResize from '../utilities/widthResize'
import closest from '../utilities/closest'
import Hamburger from './hamburger'

class MobileNav {
  constructor (nav, navList, navItem, subNav, subNavButton, breakpoint) {
    this.mobileNav = document.querySelector(nav)

    this.subNavButton = subNavButton
    this.subNav = subNav
    this.navItem = navItem
    this.navList = navList
    this.subNavs = document.querySelectorAll(subNav)
    this.subNavButtons = document.querySelectorAll(subNavButton)
    this.navList = document.querySelector(navList)

    this.toggleButton = new Hamburger()
    this.breakpoint = breakpoint
  }

  init () {
    this.toggleNavPointer = e => this.toggleNav(e)
    this.toggleSubNavPointer = e => this.toggleSubNav(e)
    this.setBreakpoints()
  }

  openNav () {
    this.mobileNav.classList.add('show')
    document.body.classList.add('show-mobile-nav')
    this.toggleButton.open()
  }

  closeNav () {
    this.mobileNav.classList.remove('show')
    document.body.classList.remove('show-mobile-nav')
    this.toggleButton.close()
    this.closeAllSubNav()
  }

  openSubNav (subNavButton, subNav) {
    const navItem = closest(subNavButton, this.navItem)

    subNavButton.classList.add('nav-secondary-button--open')
    subNav.classList.add('show')
    this.navList.classList.add('nav-secondary-dropdown--open')
    navItem.classList.add('nav-item-dropdown--open')
    Velocity(subNav, 'slideDown', { duration: 300 })
  }

  closeSubNav (subNavButton, subNav) {
    const navItem = closest(subNavButton, this.navItem)

    subNavButton.classList.remove('nav-secondary-button--open')
    subNav.classList.remove('show')
    this.navList.classList.remove('nav-secondary-dropdown--open')
    navItem.classList.remove('nav-item-dropdown--open')
    Velocity(subNav, 'slideUp', { duration: 300 })
  }

  closeAllSubNav (excludeElement) {
    for (let i = this.subNavButtons.length - 1; i >= 0; i--) {
      const button = this.subNavButtons[i]
      const subNav = this.getSubNav(button)
      this.closeSubNav(button, subNav)
    }
  }

  closeExcludeSubNav (excludeElement) {
    for (let i = this.subNavButtons.length - 1; i >= 0; i--) {
      const button = this.subNavButtons[i]
      const subNav = this.getSubNav(button)

      if (excludeElement !== subNav) {
        this.closeSubNav(button, subNav)
      }
    }
  }

  setForDesktop () {
    this.mobileNav.classList.remove('show')
    document.body.classList.remove('show-mobile-nav')
    this.mobileNav.removeAttribute('style')
    this.toggleButton.close()

    for (let i = this.subNavButtons.length - 1; i >= 0; i--) {
      const button = this.subNavButtons[i]
      const subNav = this.getSubNav(button)
      button.classList.remove('nav-secondary-button--open')
      subNav.classList.remove('show')
      subNav.removeAttribute('style')
    }

    this.setHeaderStyleDesktop()
    this.removeEvents()
  }

  setForMobile () {
    this.setEvents()
    this.closeAllSubNav()
    this.setHeaderStyleMobile()
  }

  setHeaderStyleDesktop () {
    const header = document.querySelector('.header')
    header.classList.remove('header__mobile')
    header.classList.add('header__desktop')
  }

  setHeaderStyleMobile () {
    const header = document.querySelector('.header')
    header.classList.add('header__mobile')
    header.classList.remove('header__desktop')
  }

  getSubNav (subNavButton) {
    return subNavButton.parentElement.nextElementSibling
  }

  removeEvents () {
    this.toggleButton.el().removeEventListener('click', this.toggleNavPointer)

    if (this.subNavButtons) {
      for (let i = this.subNavButtons.length - 1; i >= 0; i--) {
        const button = this.subNavButtons[i]
        button.removeEventListener('click', this.toggleSubNavPointer)
      }
    }
  }

  setEvents () {
    this.toggleButton.el().addEventListener('click', this.toggleNavPointer)

    if (this.subNavButtons) {
      for (let i = this.subNavButtons.length - 1; i >= 0; i--) {
        const button = this.subNavButtons[i]
        button.addEventListener('click', this.toggleSubNavPointer)
      }
    }
  }

  toggleNav (e) {
    e.preventDefault()
    if (this.mobileNav.classList.contains('show')) {
      this.closeNav()
    } else {
      this.openNav()
    }
  }

  toggleSubNav (e) {
    e.preventDefault()
    let el = closest(e.target, this.subNavButton)
    const subNav = this.getSubNav(el)

    if (el.classList.contains('nav-secondary-button--open')) {
      this.closeSubNav(el, subNav)
    } else {
      this.closeExcludeSubNav(subNav)
      this.openSubNav(el, subNav)
    }
  }

  setBreakpoints () {
    let bp = new Breakpoint()

    bp.checkBreakpoint(
      this.breakpoint,
      this.setForMobile.bind(this),
      this.setForDesktop.bind(this),
      true
    )

    widthResize(
      bp.checkBreakpoint.bind(
        this,
        this.breakpoint,
        this.setForMobile.bind(this),
        this.setForDesktop.bind(this)
      )
    )
  }
}

const mobileMenu = () => {
  return new MobileNav(
    '.header',
    '.nav-secondary-list',
    '.nav-item--dropdown',
    '.nav-dropdown',
    '.nav-secondary-button',
    1280
  )
}

export default mobileMenu
