// Video Modal
import $ from 'jquery'
import fancybox from 'fancybox'

class Fancybox {
  init () {
    fancybox($)
    this.openbox()
  }

  openbox () {
    $('.js-video').click(function (e) {
      e.preventDefault()
      let href = ''
      let vidId = ''
      let matchArr = $(this).attr('data-video-id').match(/^(\/)?([\d]+)$/)
      if (matchArr !== null) {
        // Could be a straight integer or an id with a prefixed '/'
        if (matchArr[1] === '/') {
          vidId = matchArr[2]
        } else {
          vidId = matchArr[0]
        }
        href = `https://player.vimeo.com/video/${vidId}?autoplay=1&color=7e003e&badge=0`
      } else {
        // Note: not much more of a check going on here other than it is not an integer
        href = `https://www.youtube.com/embed/${$(this).attr('data-video-id')}?autoplay=1`
      }

      // need to access vai "$", to avoid double click
      $.fancybox({
        padding: 0,
        href: href,
        type: 'iframe',
        width: 1280,
        height: 720,
        aspectRatio: true,
        scrolling: 'no',
        helpers: {
          media: {},
          overlay: {
            locked: false
          }
        }
      })
    })
  }
}
export default Fancybox
