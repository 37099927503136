const Velocity = require('velocity-animate')
require('velocity-animate/velocity.ui')

class AccountSettings {
  constructor () {
    this.changePasswordLink = document.querySelector('.change-password')
    this.changePasswordBlock = document.querySelector('.change-password-block')
    this.accountSettings = document.querySelector('.account-settings-block')
    if (this.accountSettings) {
      this.accountSettings = document.querySelector('.account-settings-block')
    }
  }

  init () {
    if (this.accountSettings) {
      this.createEvents()
    }
  }

  createEvents () {
    if (this.changePasswordLink) {
      this.changePasswordLink.addEventListener('click', () =>
        this.TogglePassword()
      )
    }
  }

  TogglePassword () {
    const style = getComputedStyle(this.changePasswordBlock)
    if (style.display === 'none') {
      return this.displayPasswordBlock()
    } else {
      return this.hidePasswordBlock()
    }
  }

  displayPasswordBlock () {
    this.changePasswordBlock.classList.remove('hide')
    Velocity(this.changePasswordBlock, 'slideDown', { duration: 400 })
  }

  hidePasswordBlock () {
    Velocity(this.changePasswordBlock, 'slideUp', { duration: 400 })
  }
}

export default AccountSettings
